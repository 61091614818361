import { graphql } from 'gatsby';
import { arrayOf, shape, string, bool } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';

import { Content } from '../../common/content';
import { Renderer } from '../../common/renderer';
import { CONTENT_MAPPING } from '../mapping';
import './accordion.scss';

const bem = new BEMHelper({
  name: 'content-accordion',
});

export const Accordion = ({
  id,
  headline,
  condensed,
  entries,
}) => (
  <Content id={id} {...bem(null, (condensed ? 'condensed' : ''))}>
    {headline && (<h2 {...bem('headline')}>{headline}</h2>)}
    {(entries).map(({ title, icon, contents }) => (
      <details key={title} {...bem('element')}>
        <summary {...bem('summary')}>
          <span>
            { icon.length > 0 && (
              <img src={icon[0].url} width={40} />
            )
            }
            {title}
          </span>
        </summary>
        <div {...bem('wrapper')}>
          <Renderer contents={contents} mapping={CONTENT_MAPPING} />
        </div>
      </details>
    ))}
  </Content>
);

Accordion.propTypes = {
  id: string.isRequired,
  headline: string,
  condensed: bool,
  entries: arrayOf(shape({
    title: string.isRequired,
    icon: arrayOf(shape({
      id: string,
      url: string
    })),
    contents: arrayOf(shape({
      /* ... content specific props ... */
    })).isRequired,
  })).isRequired,
};

Accordion.defaultProps = {
  headline: undefined,
};

export const fragment = graphql`
  fragment AccordionContentFragment on CraftGQL_pageContents_accordion_BlockType {
    id
    headline
    condensed
    entries {
      ...on CraftGQL_pageContentsAccordion_accordionContents_Entry {
        ...AccordionContentsFragment
        title
        icon {
          id
          url
        }
      }
    }
  }
`;
